<template>
  <div class="container">

    <h1 style="margin-top: 64px;">
      User account deletion in progress...
    </h1>

    <br/>

    <div class="spinner-border text-info"
         role="status">
      <span class="visually-hidden">
        Please be patient :)
      </span>
    </div>

    <div class="modal fade"
         id="userAccountDeletionFailedModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-hidden="true"
         aria-labelledby="userAccountDeletionFailedModalLabel">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="userAccountDeletionFailedModalLabel">
              User account deletion failed
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Dismiss"/>
          </div>

          <div class="modal-body">
            <p>
              Argh! Failed to wipe user account from server...
            </p>
            <p>
              Damn :/
            </p>
            <p>
              Please contact the site's admin about this phenomenon!
            </p>
            <p>
              {{ errorMessage ? "Reported error: " + errorMessage : "" }}
            </p>
          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    v-on:click="this.$router.push('/logout')">
              Okay
            </button>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Config from "../../public/js/config";
import Constants from "@/constants";
import {Modal} from 'bootstrap'

export default {
  name: "DeleteUserAccount",
  data()
  {
    return {
      errorMessage: ""
    }
  },
  mounted()
  {
    document.title = "Glitched Locale Server - Deleting user account...";

    const totp = this.$route.query.totp;
    const userId = this.$route.query.userId;
    const authToken = localStorage.getItem(Constants.localStorageKeyAuthToken);

    if (!authToken && !userId)
    {
      localStorage.setItem(Constants.localStorageKeyLoginReturnUrl, "/settings");
      this.$router.push("/logout");
    }

    fetch(`${Config.webApiBaseUrl}/api/v1/users?userId=${userId}&totp=${totp}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
      }
    }).then(async response =>
    {

      if (!response.ok)
      {
        new Modal(document.getElementById('userAccountDeletionFailedModal')).show();
        return;
      }

      localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
      localStorage.removeItem(Constants.localStorageKeyAuthToken);
      localStorage.removeItem(Constants.localStorageKeyUserId);

      await this.$router.push("/logout");

    }).catch(() =>
    {
      new Modal(document.getElementById("userAccountDeletionFailedModal")).show();
    });
  }
}
</script>

<style scoped>

.container
{
  padding-bottom: 16px;
}

</style>
