<template>

</template>

<script>
import Constants from "../constants";

export default {
  name: "Logout",
  mounted()
  {
    localStorage.removeItem(Constants.localStorageKeyServerVersionNumber);
    localStorage.removeItem(Constants.localStorageKeyDefibrillatorToken);
    localStorage.removeItem(Constants.localStorageKeyAuthToken);
    localStorage.removeItem(Constants.localStorageKeyUserId);
    this.$router.push("/login");
  }
}
</script>

<style scoped>

</style>
