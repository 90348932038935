<template>

  <table style="margin-top: 16px"
         class="table table-hover table-striped">
    <thead>
    <tr>
      <th scope="col">
        Id
      </th>
      <th scope="col">
        Created on
      </th>
      <th scope="col" class="hide-on-mobile">
        Expires on
      </th>
      <th scope="col">
        2FA
      </th>
    </tr>
    </thead>

    <tbody>

    <tr v-for="user in users"
        :key="user.id"
        :id="`userListRow_${user.id}`"
        @click="openUserEditModal(user)"
        :class="user.expirationUTC && (user.expirationUTC * 1000) - new Date().getTime() < 172800000 ? 'table-warning' : (darkTheme ? 'table-secondary' : '')">
      <th scope="row">
        {{ user.id }}
        <span :hidden="!user.maxQuotaBytes || user.maxQuotaBytes === 0 || user.currentQuotaBytes / user.maxQuotaBytes < 0.8">
          ❗
        </span>
      </th>
      <td>
        {{ new Date(user.creationUTC * 1000).toUTCString() }}
      </td>
      <td class="hide-on-mobile">
        {{ user.expirationUTC ? new Date(user.expirationUTC * 1000).toUTCString() : "(does not expire)" }}
      </td>
      <td>
        <input value=""
               readonly=""
               type="checkbox"
               class="form-check-input"
               :checked="user.totpEnabled"
               @click.prevent>
      </td>
    </tr>

    </tbody>
  </table>

  <div class="pagination-group">

    <div class="btn-group" role="group">
      <button type="button"
              :disabled="page <= 1"
              :class="darkTheme ? 'btn btn-secondary' : 'btn btn-primary'"
              title="Load the previous page of users"
              @click="prevPage()">
        &nbsp;&laquo;&nbsp;
      </button>

      <button :class="darkTheme ? 'btn btn-secondary btn-page-indicator-void' : 'btn btn-primary btn-page-indicator-void'">
        {{ page }} / {{ pageCount }}
      </button>

      <button type="button"
              :disabled="page === pageCount"
              :class="darkTheme ? 'btn btn-secondary' : 'btn btn-primary'"
              title="Load the next page of users"
              @click="nextPage()">
        &nbsp;&raquo;&nbsp;
      </button>
    </div>

    <div class="dropdown page-size-dropdown">
      <button :class="darkTheme ? 'btn btn-secondary dropdown-toggle' : 'btn btn-primary dropdown-toggle'"
              type="button"
              style="width: 100%;"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
        Page size: {{ pageSize }} &nbsp;
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <button class="dropdown-item"
                  type="button"
                  @click="pageSize = 5; refreshUsersList();">
            5
          </button>
        </li>
        <li>
          <button class="dropdown-item"
                  type="button"
                  @click="pageSize = 10; refreshUsersList();">
            10
          </button>
        </li>
        <li>
          <button class="dropdown-item"
                  type="button"
                  @click="pageSize = 25; refreshUsersList();">
            25
          </button>
        </li>
        <li>
          <button class="dropdown-item"
                  type="button"
                  @click="pageSize = 50; refreshUsersList();">
            50
          </button>
        </li>
      </ul>
    </div>

    <UsernameField label=""
                   ref="usernameFilter"
                   placeholder="Search by username"
                   @onChangedUsernameValue="onChangedUsernameFilterValue"/>

  </div>

  <!------------------------- MODALS ------------------------->

  <div class="modal fade"
       id="editUserModal"
       tabindex="-1"
       data-bs-keyboard="false"
       data-bs-backdrop="static"
       aria-labelledby="editUserModalLabel"
       aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">

          <h5 class="modal-title" id="editUserModalLabel">
            Edit user {{ editedUser?.id }}
          </h5>

          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Cancel"
                  @click="clearUserEditForm()"/>
        </div>

        <div class="modal-body">

          <fieldset>

            <UsernameField ref="editUserUsernameField"
                           label="Set username"
                           v-on:input="editedUserUsernameAvailable = false"
                           @onPressedEnter="editUsername()"
                           @onChangedUsernameValue="onChangedUsernameValue"/>

            <button type="button"
                    class="btn btn-primary"
                    @click="editUsername()"
                    style="margin-top: 8px"
                    title="Changes the username on the user's behalf"
                    :disabled="editingUser || !editedUserUsername">
              Submit
            </button>

            <PasswordField ref="editUserPasswordField"
                           label="Set password"
                           @onPressedEnter="editPassword()"
                           @onChangedPasswordValue="onChangedPasswordValue"/>

            <button type="button"
                    class="btn btn-primary"
                    @click="editPassword()"
                    style="margin-top: 8px"
                    title="Sets the user's password on their behalf"
                    :disabled="editingUser || editedUserPasswordStrength < 2">
              Submit
            </button>

            <PasswordField ref="editUserReadAccessPasswordField"
                           label="Set read-access password"
                           @onPressedEnter="editReadAccessPassword()"
                           @onChangedPasswordValue="onChangedReadAccessPasswordValue"/>

            <button type="button"
                    class="btn btn-primary"
                    @click="editReadAccessPassword()"
                    style="margin-top: 8px"
                    title="Sets the user's read-access password on their behalf"
                    :disabled="editingUser || (editedUserReadAccessPassword?.length > 0 && editedUserReadAccessPasswordStrength < 2)">
              Submit
            </button>

            <MaxQuotaBytesField ref="editMaxQuotaBytes"
                                @onPressedEnter="editMaxQuotaBytes()"
                                @onChangedMaxQuotaBytesValue="onChangedMaxQuotaBytesValue"/>

            <button type="button"
                    class="btn btn-primary"
                    @click="editMaxQuotaBytes()"
                    style="margin-top: 8px"
                    title="Sets the user's max quota in bytes"
                    :disabled="editingUser">
              Submit
            </button>

            <ExpirationField ref="editExpirationUTC"
                             @onPressedEnter="editExpirationUTC()"
                             @onChangedExpirationUtcValue="onChangedExpirationUtcValue"/>

            <button type="button"
                    class="btn btn-primary"
                    @click="editExpirationUTC()"
                    style="margin-top: 8px"
                    title="Sets the user's expiration date"
                    :disabled="editingUser">
              Submit
            </button>

          </fieldset>

        </div>

        <div class="modal-footer">

          <button type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                  @click="clearUserEditForm()"
                  :disabled="editingUser">
            Close
          </button>
        </div>
      </div>
    </div>

    <!------------------------- TOASTS ------------------------->

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="userAccountModFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          User account modification request rejected.
          Please analyze the response inside the network tab or check the server logs for more details.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="userAccountModSucceededToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          User account modification completed successfully.
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import Config from "../../public/js/config";
import Constants from "@/constants";
import {pageCountFromTotal} from "@/util";
import {Modal, Toast} from "bootstrap";
import PasswordField from "@/components/form-fields/PasswordField";
import UsernameField from "@/components/form-fields/UsernameField";
import MaxQuotaBytesField from "@/components/form-fields/MaxQuotaBytesField";
import ExpirationField from "@/components/form-fields/ExpirationField";
import {getDateTimeFromUtc, getUtcNow} from "@/utc";
import {debounce} from "@/debounce";

export default {
  name: "Users",
  components: {MaxQuotaBytesField, ExpirationField, UsernameField, PasswordField},
  emits: ["onEditedUser"],
  data()
  {
    return {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      usernameFilter: "",
      users: [],
      totalUsersCount: 0,
      editUserModal: null,
      editingUser: false,
      editedUser: null,
      editedUserUsername: "",
      editedUserUsernameAvailable: false,
      editedUserPassword: "",
      editedUserPasswordStrength: 0,
      editedUserReadAccessPassword: "",
      editedUserReadAccessPasswordStrength: 0,
      editedUserMaxQuotaBytes: 0,
      editedUserExpirationUTC: 0,
      darkTheme: true
    }
  },
  props: {
    isRootUser: Boolean
  },
  methods: {
    refreshUsersList: function ()
    {
      if (this.page < 1)
      {
        this.page = 1;
      }

      if (this.page >= this.pageCount)
      {
        this.page = this.pageCount;
      }

      fetch(`${Config.webApiBaseUrl}/api/v1/users?page=${this.page}&pageSize=${this.pageSize}&usernameFilter=${this.usernameFilter}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          console.error(`Fetch users failed. Response: ${JSON.stringify(response)}`);
          return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items)
        {
          console.error(`Invalid response envelope: ${JSON.stringify(responseEnvelope)}`);
          return;
        }

        this.users = responseEnvelope.items;
        this.totalUsersCount = responseEnvelope.count;
        this.pageCount = pageCountFromTotal(this.totalUsersCount, this.pageSize);

        let refresh = false;

        if (this.page > this.pageCount)
        {
          this.page = this.pageCount;
          refresh = true;
        }

        if (this.pageCount > 0 && this.page === 0)
        {
          this.page = 1;
          refresh = true;
        }

        if (refresh)
        {
          this.refreshUsersList();
        }
      }).catch(error =>
      {
        console.error(`Fetch users failed. Error: ${JSON.stringify(error)}`);
      });
    },
    openUserEditModal: function (user)
    {
      if (!this.editUserModal)
      {
        this.editUserModal = new Modal(document.getElementById('editUserModal'));
      }

      this.editedUser = user;

      this.editedUserExpirationUTC = user.expirationUTC;
      this.$refs.editExpirationUTC.$data.expirationDateTime = getDateTimeFromUtc(user.expirationUTC);

      this.editedUserMaxQuotaBytes = user.maxQuotaBytes;
      this.$refs.editMaxQuotaBytes.$data.maxQuotaBytes = user.maxQuotaBytes;

      this.editUserModal.show();
    },
    clearUserEditForm: function ()
    {
      this.editedUserUsername = "";
      this.editedUserUsernameAvailable = false;
      this.editedUserPassword = "";
      this.editedUserPasswordStrength = 0;
      this.editedUserReadAccessPassword = "";
      this.editedUserReadAccessPasswordStrength = 0;
      this.editedUserMaxQuotaBytes = 0;
      this.editedUserExpirationUTC = 0;

      this.$refs.editUserUsernameField.clear();
      this.$refs.editUserPasswordField.clear();
      this.$refs.editUserReadAccessPasswordField.clear();
      this.$refs.editMaxQuotaBytes.clear();
      this.$refs.editExpirationUTC.clear();
    },
    onChangedUsernameValue: function (username, available)
    {
      this.editedUserUsername = username;
      this.editedUserUsernameAvailable = available;
    },
    onChangedUsernameFilterValue: debounce(function (username, available)
    {
      this.usernameFilter = username;
      this.refreshUsersList();
    }, 128),
    onChangedPasswordValue: function (pw, strength)
    {
      this.editedUserPassword = pw;
      this.editedUserPasswordStrength = strength;
    },
    onChangedReadAccessPasswordValue: function (pw, strength)
    {
      this.editedUserReadAccessPassword = pw;
      this.editedUserReadAccessPasswordStrength = strength;
    },
    onChangedMaxQuotaBytesValue: function (maxQuotaBytes)
    {
      this.editedUserMaxQuotaBytes = maxQuotaBytes;
    },
    onChangedExpirationUtcValue: function (expUtc)
    {
      this.editedUserExpirationUTC = expUtc;
    },
    handleEditResponse: function (response, fieldRef, clearField = true)
    {
      this.editingUser = false;
      new Toast(document.getElementById(response.ok ? "userAccountModSucceededToast" : "userAccountModFailedToast")).show();

      if (response.ok)
      {
        if (clearField)
        {
          fieldRef?.clear();
        }
        this.$emit("onEditedUser");
      }
      else
      {
        console.error(`User account modification failed. Error: ${JSON.stringify(response)}`);
      }
    },
    handleEditResponseError: function (error)
    {
      this.editingUser = false;
      new Toast(document.getElementById("userAccountModFailedToast")).show();

      console.error(`User account modification failed. Error: ${JSON.stringify(error)}`);
    },
    editUsername: function ()
    {
      if (this.editingUser || !confirm("Are you sure? Unless you inform this user about their new username, they won't be able to log in anymore!"))
      {
        return;
      }

      this.editingUser = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/username`, {
        method: "PUT",
        body: JSON.stringify({
          userId: this.editedUser.id,
          newUsername: this.editedUserUsername
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.handleEditResponse(response, this.$refs.editUserUsernameField);
      }).catch(error =>
      {
        this.handleEditResponseError(error);
      });
    },
    editPassword: function ()
    {
      if (this.editingUser || !confirm("Are you sure? Unless you inform this user about their new password, they won't be able to log in anymore!"))
      {
        return;
      }

      this.editingUser = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/passwd`, {
        method: "PUT",
        body: JSON.stringify({
          userId: this.editedUser.id,
          newPassword: this.editedUserPassword
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.handleEditResponse(response, this.$refs.editUserPasswordField);
      }).catch(error =>
      {
        this.handleEditResponseError(error);
      });
    },
    editReadAccessPassword: function ()
    {
      if (this.editingUser || !confirm("Are you sure? Unless you inform this user about their new read-access password, it could be possible that they lose access to some of their translations used by their clients!"))
      {
        return;
      }

      this.editingUser = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/passwd/read-access`, {
        method: "PUT",
        body: JSON.stringify({
          userId: this.editedUser.id,
          newPassword: this.editedUserReadAccessPassword
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.handleEditResponse(response, this.$refs.editUserReadAccessPasswordField);
      }).catch(error =>
      {
        this.handleEditResponseError(error);
      });
    },
    editExpirationUTC: function ()
    {
      if (this.editingUser)
      {
        return;
      }

      this.editingUser = true;
      const utcNow = getUtcNow();

      fetch(`${Config.webApiBaseUrl}/api/v1/users/exp`, {
        method: "PUT",
        body: JSON.stringify({
          userId: this.editedUser.id,
          newExpirationUTC: this.editedUserExpirationUTC && this.editedUserExpirationUTC > utcNow ? this.editedUserExpirationUTC : null
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.handleEditResponse(response, this.$refs.editExpirationUTC, false);

        if (response.ok)
        {
          this.$refs.editExpirationUTC.$data.expirationDateTime = getDateTimeFromUtc(this.editedUserExpirationUTC);
          this.editedUser.expirationUTC = this.editedUserExpirationUTC;
          this.refreshUsersList();
        }
      }).catch(error =>
      {
        this.handleEditResponseError(error);
      });
    },
    editMaxQuotaBytes: function ()
    {
      if (this.editingUser)
      {
        return;
      }

      this.editingUser = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/quota`, {
        method: "PUT",
        body: JSON.stringify({
          userId: this.editedUser.id,
          newMaxQuotaBytes: this.editedUserMaxQuotaBytes ?? 0
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.handleEditResponse(response, this.$refs.editMaxQuotaBytes, false);

        if (response.ok)
        {
          this.editedUser.maxQuotaBytes = this.$refs.editMaxQuotaBytes.$data.maxQuotaBytes = this.editedUserMaxQuotaBytes;
        }
      }).catch(error =>
      {
        this.handleEditResponseError(error);
      });
    },
    nextPage: function ()
    {
      if (this.page === this.pageCount)
      {
        return;
      }

      this.page++;
      this.refreshUsersList();
    },
    prevPage: function ()
    {
      if (this.page === 1)
      {
        return;
      }

      this.page--;
      this.refreshUsersList();
    }
  },
  mounted()
  {
    this.darkTheme = JSON.parse(localStorage.getItem(Constants.localStorageKeyDarkTheme) ?? JSON.stringify(Constants.settingsDefaultDarkTheme));

    this.emitter.on(Constants.eventNameChangedTheme, darkTheme =>
    {
      this.darkTheme = darkTheme;
    });

    if (!this.isRootUser)
    {
      return;
    }

    let pageSize = localStorage.getItem(Constants.localStorageKeyUsersListPageSize);

    if (!pageSize || isNaN(pageSize))
    {
      pageSize = 10;
    }

    this.pageSize = pageSize;

    this.refreshUsersList();
  },
  watch: {
    pageSize: function (newPageSize)
    {
      localStorage.setItem(Constants.localStorageKeyUsersListPageSize, newPageSize);
    }
  }
}

</script>

<style scoped>

.form-check-input,
.table-secondary,
.table-warning
{
  cursor: pointer;
}

.pagination-group
{
  display: flex;
  column-gap: 14px;
}

.pagination-group > p
{
  margin-left: auto;
  text-align: right;
  font-size: 0.875rem;
}

.page-link
{
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

@media (max-width: 576px)
{
  .hide-on-mobile
  {
    display: none;
  }

  .pagination-group
  {
    display: block;
  }

  .page-size-dropdown
  {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .btn-group
  {
    width: 100%;
  }
}

@media (min-width: 576px)
{
  .hide-on-desktop
  {
    display: none;
  }
}

.btn-page-indicator-void
{
  cursor: default;
}

.btn-page-indicator-void:focus
{
  outline: none;
  box-shadow: none;
}

</style>
