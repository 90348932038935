<template>

  <button style="margin-top: 8px"
          class="btn btn-outline-info"
          v-on:click="createNewUser()">
    Create new user
  </button>

  <!-- User creation form modal -->
  <div class="modal fade"
       id="createNewUserModal"
       tabindex="-1"
       data-bs-keyboard="false"
       data-bs-backdrop="static"
       aria-labelledby="createNewUserModalLabel"
       aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">

          <h5 class="modal-title" id="createNewUserModalLabel">
            Create new user
          </h5>

          <!--
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Cancel"/>
          -->
        </div>

        <div class="modal-body">

          <fieldset>

            <UsernameField ref="username"
                           v-on:input="usernameAvailable = false"
                           @onPressedEnter="submitUserCreationRequest()"
                           @onChangedUsernameValue="onChangedUsernameValue"/>

            <PasswordField label="Password"
                           ref="password"
                           input-id="password"
                           @onPressedEnter="submitUserCreationRequest()"
                           @onChangedPasswordValue="onChangedPasswordValue"/>

            <PasswordField label="Read access password"
                           ref="readAccessPassword"
                           input-id="readAccessPassword"
                           @onPressedEnter="submitUserCreationRequest()"
                           @onChangedPasswordValue="onChangedReadAccessPasswordValue"/>

            <MaxQuotaBytesField ref="maxQuotaBytes"
                                @onPressedEnter="submitUserCreationRequest()"
                                @onChangedMaxQuotaBytesValue="onChangedMaxQuotaBytesValue"/>

            <ExpirationField ref="expirationUTC"
                             @onPressedEnter="submitUserCreationRequest()"
                             @onChangedExpirationUtcValue="onChangedExpirationUtcValue"/>

          </fieldset>

        </div>

        <div class="modal-footer">

          <button type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                  :disabled="creatingUser">
            Cancel
          </button>

          <button type="button"
                  class="btn btn-success"
                  :disabled="creatingUser || !usernameAvailable || passwordStrength < 2 || (readAccessPassword !== '' && readAccessPasswordStrength < 2)"
                  v-on:click="submitUserCreationRequest()">

            <div class="spinner-border spinner-border-sm text-info"
                 :hidden="!creatingUser"
                 role="status">

                  <span class="visually-hidden">
                    Creating user account...
                  </span>
            </div>

            Create

          </button>

        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="userCreationFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          User creation request rejected.
          Please analyze the response inside the network tab or check the server logs for more details.
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import {Modal, Toast} from "bootstrap";
import UsernameField from "@/components/form-fields/UsernameField";
import PasswordField from "@/components/form-fields/PasswordField";
import {debounce} from "@/debounce";
import {getUtcNow} from "@/utc";
import Config from "../../public/js/config";
import Constants from "@/constants";
import {getNicelyFormattedFileSize} from "@/bytes";
import MaxQuotaBytesField from "@/components/form-fields/MaxQuotaBytesField";
import ExpirationField from "@/components/form-fields/ExpirationField";

export default {
  name: "CreateUserAccount",
  components: {ExpirationField, MaxQuotaBytesField, PasswordField, UsernameField},
  data()
  {
    return {
      username: "",
      usernameAvailable: false,
      password: "",
      passwordStrength: 0,
      readAccessPassword: "",
      readAccessPasswordStrength: 0,
      expirationUTC: 0,
      maxQuotaBytes: 0,
      creatingUser: false,
      modal: null
    }
  },
  emits: ["onCreatedUserSuccessfully"],
  methods: {
    clear: function ()
    {
      this.username = "";
      this.usernameAvailable = false;
      this.password = "";
      this.passwordStrength = 0;
      this.readAccessPassword = "";
      this.readAccessPasswordStrength = 0;
      this.expirationUTC = 0;
      this.maxQuotaBytes = 0;
      this.creatingUser = false;

      this.$refs.username.clear();
      this.$refs.password.clear();
      this.$refs.maxQuotaBytes.clear();
      this.$refs.expirationUTC.clear();
      this.$refs.readAccessPassword.clear();
    },
    createNewUser: function ()
    {
      if (!this.modal)
      {
        this.modal = new Modal(document.getElementById("createNewUserModal"));
      }
      this.modal.show();
    },
    submitUserCreationRequest: function ()
    {
      if (this.creatingUser)
      {
        return;
      }

      this.creatingUser = true;

      const userCreationRequestDto = {
        username: this.username,
        password: this.password,
        readAccessPassword: this.readAccessPassword,
        expirationUTC: this.expirationUTC > 0 ? this.expirationUTC : null,
        maxQuotaBytes: this.maxQuotaBytes > 0 ? this.maxQuotaBytes : null,
      };

      fetch(`${Config.webApiBaseUrl}/api/v1/users`, {
        method: "POST",
        body: JSON.stringify(userCreationRequestDto),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          this.creatingUser = false;
          new Toast(document.getElementById("userCreationFailedToast"), {delay: 6677}).show();
          return;
        }

        this.$emit("onCreatedUserSuccessfully");
        this.modal?.hide();
      }).catch(() =>
      {
        this.creatingUser = false;
        new Toast(document.getElementById("userCreationFailedToast"), {delay: 6677}).show();
      });
    },
    onChangedUsernameValue: function (username, available)
    {
      this.username = username;
      this.usernameAvailable = available;
    },
    onChangedPasswordValue: function (pw, strength)
    {
      this.password = pw;
      this.passwordStrength = strength;
    },
    onChangedReadAccessPasswordValue: function (pw, strength)
    {
      this.readAccessPassword = pw;
      this.readAccessPasswordStrength = strength;
    },
    onChangedMaxQuotaBytesValue: function (maxQuotaBytes)
    {
      this.maxQuotaBytes = maxQuotaBytes;
    },
    onChangedExpirationUtcValue: function (expUtc)
    {
      this.expirationUTC = expUtc;
    }
  }
}
</script>

<style scoped>

button
{
  padding-left: 16px;
  padding-right: 16px;
}

.badge-btn
{
  margin-left: 2px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.badge-btn:hover
{
  cursor: pointer;
  filter: brightness(0.8);
}

.container
{
  padding-bottom: 16px;
}

</style>
