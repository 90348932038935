export function pageCountFromTotal(totalCount, pageSize)
{
    return Math.floor((totalCount + (pageSize - 1)) / pageSize);
}

export function stringToBase64URL(str)
{
    return bytesToBase64URL(new TextEncoder().encode(str));
}

export function bytesToBase64URL(bytes)
{
    const b = Array.from(bytes, (byte) => String.fromCodePoint(byte),).join("");
    return btoa(b).replace('+', '-').replace('/', '_').replace(/=+$/, '');
}