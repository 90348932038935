import Constants from "@/constants";
import Config from "../public/js/config";

let _refreshingAuthToken = false;

export function refreshingAuthToken()
{
    return _refreshingAuthToken;
}

export function refreshAuthToken(router = null)
{
    let expiredTokenString = localStorage.getItem(Constants.localStorageKeyAuthToken);
    let defibrillatorTokenString = localStorage.getItem(Constants.localStorageKeyDefibrillatorToken);

    if (_refreshingAuthToken || !defibrillatorTokenString)
    {
        return Promise.resolve();
    }

    _refreshingAuthToken = true;

    return fetch(`${Config.webApiBaseUrl}/api/v1/login/revive`, {
        method: "POST", body: JSON.stringify({
            expiredToken: expiredTokenString,
            defibrillatorToken: defibrillatorTokenString
        }), headers: {
            "Content-Type": "application/json; charset=UTF-8"
        }
    }).then(async response =>
    {
        _refreshingAuthToken = false;

        if (!response.ok)
        {
            router?.push("/logout");
            return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
            router?.push("/logout");
            return;
        }

        const loginResponseDto = responseEnvelope.items[0];

        localStorage.setItem(Constants.localStorageKeyDefibrillatorToken, loginResponseDto.defibrillatorToken);
        localStorage.setItem(Constants.localStorageKeyAuthToken, loginResponseDto.token);
        localStorage.setItem(Constants.localStorageKeyUserId, loginResponseDto.userId);

    }).catch(() =>
    {
        _refreshingAuthToken = false;
        localStorage.removeItem(Constants.localStorageKeyDefibrillatorToken);
        localStorage.removeItem(Constants.localStorageKeyAuthToken);
        localStorage.removeItem(Constants.localStorageKeyUserId);
        router?.push("/logout");
    });
}

export function whoAmI()
{
    return fetch(`${Config.webApiBaseUrl}/api/v1/users/whoami`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
    });
}