import {createApp} from "vue";
import {createRouter, createWebHistory} from "vue-router";

import mitt from 'mitt';
import "bootstrap";
import App from "./App.vue";
import Login from "./components/Login.vue";
import Logout from "./components/Logout.vue";
import Settings from "./components/Settings";
import Translations from "./components/Translations";
import DeleteUserAccount from "./components/DeleteUserAccount";

const emitter = mitt();

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: "/", component: Translations},
        {path: "/login", component: Login},
        {path: "/logout", component: Logout},
        {path: "/settings", component: Settings},
        {path: "/delete-user-account", component: DeleteUserAccount},
    ]
});

export default router;

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(router);
app.mount("#app");
