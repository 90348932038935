<template>

  <Nav/>

  <div class="container">
    <h1>
      Settings
    </h1>

    <br/>

    <div class="accordion"
         id="accordionSettings">

      <!-- Change password -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="headingChangePassword">
          <button class="accordion-button collapsed"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseChangePassword"
                  aria-controls="collapseChangePassword">
            Change password
          </button>
        </h2>
        <div id="collapseChangePassword"
             class="accordion-collapse collapse"
             aria-labelledby="headingChangePassword"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-md-4">

                <fieldset>
                  <div class="form-group">

                    <label class="form-label mt-4">
                      Current password
                    </label>

                    <input type="password"
                           id="currentPassword"
                           class="form-control"
                           v-on:keyup.enter="changePassword()"
                           v-model="currentPassword"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <PasswordField label="New password"
                                 ref="newPassword"
                                 input-id="newPassword"
                                 @onPressedEnter="changePassword()"
                                 @onChangedPasswordValue="onChangedPasswordValue"/>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Confirm new password
                    </label>

                    <input type="password"
                           class="form-control"
                           v-on:keyup.enter="changePassword()"
                           v-model="confirmNewPassword"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Two-factor authentication
                    </label>

                    <input type="text"
                           class="form-control"
                           minlength="6"
                           maxlength="6"
                           v-on:keyup.enter="changePassword()"
                           v-model="confirmNewPasswordTotp"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <br/>

                  <button type="submit"
                          id="changePasswordButton"
                          class="btn btn-primary"
                          style="min-width: 100px"
                          :disabled="changingPassword || (!currentPassword && !newPassword) || this.newPasswordStrength < 2"
                          v-on:click="changePassword()">

                    <div class="spinner-border spinner-border-sm text-info"
                         :hidden="!changingPassword"
                         role="status">

                    <span class="visually-hidden">
                      Changing password...
                    </span>

                    </div>

                    Submit

                  </button>
                </fieldset>

              </div>

              <div class="col-md-4"></div>
            </div>

          </div>
        </div>
      </div>

      <!-- Change read-access password -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="headingChangeReadAccessPassword">
          <button class="accordion-button collapsed"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseChangeReadAccessPassword"
                  aria-controls="collapseChangeReadAccessPassword">
            Change read-access password
          </button>
        </h2>
        <div id="collapseChangeReadAccessPassword"
             class="accordion-collapse collapse"
             aria-labelledby="headingChangeReadAccessPassword"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-md-4">

                <fieldset>
                  <div class="form-group">

                    <label class="form-label mt-4">
                      Current password
                    </label>

                    <input type="password"
                           id="currentReadAccessPassword"
                           class="form-control"
                           v-on:keyup.enter="changeReadAccessPassword()"
                           v-model="currentReadAccessPassword"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <PasswordField label="New password"
                                 ref="newReadAccessPassword"
                                 input-id="newReadAccessPassword"
                                 @onPressedEnter="changeReadAccessPassword()"
                                 @onChangedPasswordValue="onChangedReadAccessPasswordValue"/>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Confirm new password
                    </label>

                    <input type="password"
                           class="form-control"
                           v-on:keyup.enter="changeReadAccessPassword()"
                           v-model="confirmNewReadAccessPassword"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Two-factor authentication
                    </label>

                    <input type="text"
                           class="form-control"
                           minlength="6"
                           maxlength="6"
                           v-on:keyup.enter="changeReadAccessPassword()"
                           v-model="confirmNewReadAccessPasswordTotp"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <br/>

                  <button type="submit"
                          id="changeReadAccessPasswordButton"
                          class="btn btn-primary"
                          style="min-width: 100px"
                          :disabled="changingReadAccessPassword"
                          v-on:click="changeReadAccessPassword()">

                    <div class="spinner-border spinner-border-sm text-info"
                         :hidden="!changingReadAccessPassword"
                         role="status">

                    <span class="visually-hidden">
                      Changing password...
                    </span>

                    </div>

                    Submit

                  </button>
                </fieldset>

              </div>

              <div class="col-md-4"></div>
            </div>

          </div>
        </div>
      </div>

      <!-- Manage API Keys -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="headingApiKeys">
          <button class="accordion-button collapsed"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseApiKeys"
                  aria-controls="collapseApiKeys">
            API Keys
          </button>
        </h2>

        <div id="collapseApiKeys"
             class="accordion-collapse collapse"
             aria-labelledby="headingApiKeys"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <br/>

            <button class="btn btn-primary"
                    type="button"
                    id="button-create-api-key"
                    title="Click here to create a new API Key."
                    style="min-width: 128px;"
                    @click="createNewApiKey()">
              Create
            </button>

            <table v-if="apiKeys && apiKeys.length !== 0"
                   class="table table-hover table-striped"
                   style="margin-top: 16px">
              <thead>
              <tr>
                <th scope="col">
                  Key ID
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col"
                    class="hide-on-mobile">
                  Expires on
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="apiKey in apiKeys"
                  @click="editApiKey(apiKey)"
                  :key="apiKey.key"
                  :id="`apiKey_${apiKey.id}`"
                  :title="`ID: ${apiKey.id}\n\nCreated on: ${new Date(apiKey.timestampUTC * 1000).toUTCString()}\n\nDescription: ${apiKey.description}`"
                  :class="getTableRowColorByExpirationDate(apiKey.expirationUTC)">
                <th scope="row">
                  {{ apiKey.id }}
                </th>
                <td :title="apiKey.description"
                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{ apiKey.description }}
                </td>
                <td class="hide-on-mobile">
                  {{ apiKey.expirationUTC ? new Date(apiKey.expirationUTC * 1000).toUTCString() : "(does not expire)" }}
                </td>
              </tr>
              </tbody>
            </table>

            <p v-else>
              <br/>
              <i>No API keys found. Click the "Create" button above to configure your first API key now!</i>
            </p>
          </div>
        </div>
      </div>

      <!-- Change username -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="headingChangeUsername">
          <button class="accordion-button collapsed"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseChangeUsername"
                  aria-controls="collapseChangeUsername">
            Change username
          </button>
        </h2>
        <div id="collapseChangeUsername"
             class="accordion-collapse collapse"
             aria-labelledby="headingChangeUsername"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-md-4">

                <fieldset>

                  <UsernameField v-on:input="usernameAvailable = false"
                                 label="New username"
                                 ref="newUsername"
                                 @onPressedEnter="changeUsername()"
                                 @onChangedUsernameValue="onChangedUsernameValue"/>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Current password
                    </label>

                    <input type="password"
                           id="currentPasswordForUsernameMod"
                           class="form-control"
                           v-on:keyup.enter="changeUsername()"
                           v-model="currentPasswordForUsernameMod"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <div class="form-group">

                    <label class="form-label mt-4">
                      Two-factor authentication
                    </label>

                    <input type="text"
                           class="form-control"
                           minlength="6"
                           maxlength="6"
                           v-on:keyup.enter="changeUsername()"
                           v-model="confirmNewUsernameTotp"
                           @focus="$event.target.select()"
                           placeholder="">
                  </div>

                  <br/>

                  <button type="submit"
                          id="changeUsernameButton"
                          class="btn btn-primary"
                          style="min-width: 100px"
                          :disabled="!usernameAvailable || changingUsername"
                          v-on:click="changeUsername()">

                    <div class="spinner-border spinner-border-sm text-info"
                         :hidden="!changingUsername"
                         role="status">

                    <span class="visually-hidden">
                      Changing username...
                    </span>

                    </div>

                    Submit

                  </button>
                </fieldset>

              </div>

              <div class="col-md-4"></div>
            </div>

          </div>
        </div>
      </div>

      <!-- Change default locales list -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="headingChangeDefaultLocales">
          <button class="accordion-button collapsed"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseChangeDefaultLocales"
                  aria-controls="collapseChangeUsername">
            Change default locales
          </button>
        </h2>
        <div id="collapseChangeDefaultLocales"
             class="accordion-collapse collapse"
             aria-labelledby="headingChangeDefaultLocales"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <div class="row">
              <div class="col-md-4"></div>

              <div class="col-md-4">

                <fieldset>

                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        v-for="defaultLocale in defaultLocales"
                        :key="defaultLocale">

                      {{ defaultLocale }}

                      <span class="badge bg-danger rounded-pill remove-locale-from-list-badge-btn"
                            @click="removeDefaultLocaleFromList(defaultLocale)">
                        Remove
                      </span>
                    </li>
                  </ul>

                  <div class="input-group mb-3 mt-3">
                    <input type="text"
                           class="form-control"
                           v-model="newDefaultLocale"
                           ref="newDefaultLocale"
                           v-on:keyup.enter="addNewDefaultLocaleToList()"
                           placeholder="Custom locale identifier"
                           aria-label="Custom locale identifier"
                           aria-describedby="button-add-locale">
                    <button class="btn btn-primary"
                            type="button"
                            id="button-add-locale"
                            :disabled="!newDefaultLocale"
                            @click="addNewDefaultLocaleToList()">
                      Add
                    </button>
                  </div>

                  <button type="button"
                          style="min-width: 96px;"
                          @click="saveDefaultLocales()"
                          :disabled="savingDefaultLocalesList || !changedDefaultLocalesList"
                          class="btn btn-outline-info">
                    Save
                  </button>

                </fieldset>

              </div>

              <div class="col-md-4"></div>
            </div>

          </div>
        </div>
      </div>

      <!-- Enabling/disabling 2FA -->

      <div class="accordion-item">
        <h2 class="accordion-header"
            id="heading2FA">
          <button class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse2FA"
                  aria-expanded="false"
                  aria-controls="collapse2FA">
            {{ totpEnabled ? "Disable" : "Enable" }} two-factor authentication
          </button>
        </h2>
        <div id="collapse2FA"
             class="accordion-collapse collapse"
             aria-labelledby="heading2FA"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">
            <div v-if="totpEnabled">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <p>
                    Are you sure?
                  </p>
                  <p>
                    Having 2FA enabled is actually a pretty good security measure.
                    Disabling it will reduce your account's security!
                  </p>
                  <p>
                    To proceed with the deactivation of 2FA for your user account,
                    enter a currently valid 2FA token down into the text field below and
                    hit the red confirmation button.
                  </p>

                  <br/>
                  <hr/>

                  <div class="form-group">
                    <label class="form-label mt-4">
                      Two-factor authentication
                    </label>

                    <div class="row">
                      <div class="col-sm-12 col-md-12">
                        <div class="input-group mb-3"
                             style="margin-top: 8px">

                          <input type="text"
                                 class="form-control"
                                 minlength="6"
                                 maxlength="6"
                                 v-model="disableTwoFactorAuthTotp"
                                 v-on:keyup.enter="disableTwoFactorAuth()"
                                 @focus="$event.target.select()"
                                 placeholder="E.g. 123456">
                          <button class="btn btn-danger"
                                  type="button"
                                  v-on:click="disableTwoFactorAuth()"
                                  :disabled="disableTwoFactorAuthTotp?.length !== 6">
                            Disable 2FA
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <p>
                    Great idea!
                  </p>
                  <p>
                    In fact, Two-Factor Authentication adds a substantial layer of security to your user account.
                  </p>
                  <p>
                    To find out more about what 2FA is, check out its
                    <a target="_blank"
                       href="https://en.wikipedia.org/wiki/Multi-factor_authentication">
                      Wikipedia article
                    </a>.
                  </p>

                  <br/>

                  <button type="button"
                          class="btn btn-success btn-lg enable-2fa-btn"
                          v-on:click="enableTwoFactorAuth()">
                    Enable two-factor authentication
                  </button>

                </div>
                <div class="col-md-4"></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- User account deletion -->

      <div v-if="!isRootUser"
           class="accordion-item">
        <h2 class="accordion-header"
            id="headingUserDeletion">
          <button class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseUserDeletion"
                  aria-expanded="false"
                  aria-controls="collapseUserDeletion">
            Delete user account
          </button>
        </h2>
        <div id="collapseUserDeletion"
             class="accordion-collapse collapse"
             aria-labelledby="headingUserDeletion"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">
            <div class="row">
              <div class="col-lg-4"></div>
              <div class="col-lg-4">
                <p>
                  Are you sure?
                </p>
                <p>
                  Deleting an account is IRREVERSIBLE and thus cannot be undone!
                </p>
                <p>
                  To confirm your intent, enter the word "DELETE" down into the text field below and hit the dangerous
                  red
                  button in order to proceed.
                </p>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <div class="input-group mb-3"
                           style="margin-top: 8px">
                        <input type="text"
                               class="form-control"
                               v-model="confirmUserDeletionWord"
                               @focus="$event.target.select()"
                               placeholder="Uh oh...">
                        <button class="btn btn-danger"
                                type="button"
                                v-on:click="deleteUserAccount()"
                                :disabled="confirmUserDeletionWord?.toLowerCase() !== 'delete'">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Users management -->

      <div v-if="isRootUser"
           class="accordion-item">
        <h2 class="accordion-header"
            id="headingUsers">
          <button class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseUsers"
                  aria-expanded="false"
                  aria-controls="collapseUsers">
            Users
          </button>
        </h2>
        <div id="collapseUsers"
             class="accordion-collapse collapse"
             aria-labelledby="headingUsers"
             data-bs-parent="#accordionSettings">
          <div class="accordion-body">

            <CreateUserAccount ref="userCreationModal"
                               @onCreatedUserSuccessfully="onCreatedUserSuccessfully()"/>

            <Users ref="users"
                   :is-root-user="isRootUser"/>

          </div>
        </div>
      </div>

    </div>

    <div :hidden="!expirationUTC || expirationUTC === 0"
         style="margin-top: 32px;"
         class="row">
      <div class="col-md-8"></div>
      <div class="col-md-4">

      </div>
    </div>

    <div style="margin-top: 32px; text-align: right;"
         class="row">
      <div class="col-md-8"
           :hidden="!expirationUTC || expirationUTC === 0"></div>
      <div class="col-md-4"
           :hidden="!expirationUTC || expirationUTC === 0">
        <small :style="expiresSoon ? 'color: red' : ''">
          Account active until: {{ new Date(expirationUTC * 1000).toLocaleDateString() }}
        </small>
      </div>
      <div class="col-md-8"
           :hidden="!maxQuotaBytes || maxQuotaBytes === 0"></div>
      <div class="col-md-4"
           :hidden="!maxQuotaBytes || maxQuotaBytes === 0">
        <small>
          Used storage quota: {{ getUsedQuotaString() }}
        </small>
        <div class="progress">
          <div role="progressbar"
               :class="`progress-bar ${currentQuotaBytes / maxQuotaBytes > 0.9 ? 'bg-danger' : currentQuotaBytes / maxQuotaBytes > 0.8 ? 'bg-warning' : 'bg-info'}`"
               :style="`width: ${currentQuotaBytes / Math.max(maxQuotaBytes, 1) * 100}%`"
               :aria-valuenow="currentQuotaBytes"
               :aria-valuemin="0"
               :aria-valuemax="maxQuotaBytes">
          </div>
        </div>
      </div>
    </div>

    <!------------------------- MODALS ------------------------->

    <!-- Confirm user account deletion modal -->
    <div class="modal fade"
         id="confirmUserDeletionModal"
         tabindex="-1"
         aria-labelledby="confirmUserDeletionModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="confirmUserDeletionModalLabel">
              Last warning! Are you absolutely sure?
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    v-on:click="confirmUserDeletionWord = ''"
                    aria-label="Cancel"/>
          </div>

          <div class="modal-body">
            <p>
              Confirm your intent of <strong>irreversibly</strong> deleting your user account one <strong>last</strong>
              time!
            </p>
            <p>
              Please be aware of the following consequences:
            </p>
            <ul>
              <li>
                All of your translations will be <strong>immediately</strong> destroyed without any chance of recovery.
              </li>
              <li>
                Any clients that are currently making use of your translations and/or depend on your user account
                <strong>will instantly lose access</strong> to it and might break if they do not have fallback values in
                place for their requested string values.
              </li>
              <li>
                You are not entitled to any refund by default.
              </li>
            </ul>
          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    v-on:click="confirmUserDeletionWord = ''">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    v-on:click="confirmUserAccountDeletion()">
              Delete
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- Enter TOTP for confirming user account deletion dialog -->
    <div class="modal fade"
         id="confirmUserDeletionTotpModal"
         tabindex="-1"
         aria-labelledby="confirmUserDeletionTotpModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="confirmUserDeletionTotpModalLabel">
              Please provide your current 2FA token
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    v-on:click="confirmUserDeletionWord = ''"
                    aria-label="Cancel"/>
          </div>

          <div class="modal-body">
            <p>
              Please authorize your intent by entering the currently valid two-factor authentication token for your user
              account.
            </p>
            <input type="text"
                   class="form-control"
                   minlength="6"
                   maxlength="6"
                   v-model="confirmUserDeletionTotp"
                   @focus="$event.target.select()"
                   placeholder="E.g. 123456">
          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    v-on:click="confirmUserDeletionWord = ''; confirmUserDeletionTotp = ''">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    v-on:click="deleteUserAccount()">
              Delete
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- Enable 2FA modal -->
    <div class="modal fade"
         id="enableTwoFactorAuthModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-labelledby="enableTwoFactorAuthModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="enableTwoFactorAuthModalLabel">
              Enable Two-Factor Authentication
            </h5>
          </div>

          <div class="modal-body">

            <div v-if="enableTwoFactorAuthError">
              <p>
                Activation of 2FA failed server-side for an unknown reason.
              </p>
            </div>

            <div v-else-if="generatingTwoFactorAuthSecret">
              <div class="d-flex align-items-center">
                <strong>2FA activation in progress...</strong>
                <div class="spinner-border ms-auto"
                     role="status"
                     aria-hidden="true"></div>
              </div>
            </div>

            <div v-else>
              <p>
                The following is your 2FA secret. Please store it somewhere safe!
                If you lose it and your 2FA-device, you lose access to your user account!
              </p>
              <p>
                Manually enter your 2FA secret into a 2FA app of your choice
                (e.g.
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                  Google Authenticator
                </a>),
                or scan the following QR-code with it.
              </p>

              <div class="row"
                   style="margin-top: 24px;">
                <div class="col-md"></div>
                <div class="col-md-5">
                  <qrcode-vue size="256"
                              level="M"
                              margin="2"
                              render-as="svg"
                              id="totpActivationQR"
                              :value="`otpauth://totp/Glitched Locale Server?secret=${totpSecret}`"/>
                </div>
                <div class="col-md"></div>
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  2FA Secret
                </label>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <div class="input-group mb-3">
                        <input type="text"
                               readonly
                               class="form-control"
                               v-model="totpSecret"
                               @focus="$event.target.select()">
                        <button class="btn btn-primary"
                                type="button"
                                v-on:click="copyTotpSecret()"
                                :disabled="!totpSecret">
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">

                <p style="margin: 0;">
                  Confirm your action by entering the generated 6-digit 2FA token
                  (aka. "TOTP") below and click on the "Enable" button:
                </p>

                <label class="form-label mt-4">
                  Two-factor authentication
                </label>

                <input type="text"
                       class="form-control"
                       minlength="6"
                       maxlength="6"
                       v-on:keyup.enter="confirmEnableTwoFactorAuth()"
                       v-model="enableTwoFactorAuthTotp"
                       @focus="$event.target.select()"
                       placeholder="E.g. 123456">
              </div>
            </div>

          </div>

          <div class="modal-footer">

            <div v-if="enableTwoFactorAuthError">
              <button type="button"
                      class="btn btn-danger"
                      data-bs-dismiss="modal"
                      v-on:click="enableTwoFactorAuthTotp = ''; totpSecret = '';">
                Okay :/
              </button>
            </div>

            <div v-else>
              <button type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      :disabled="enablingTwoFactorAuth"
                      v-on:click="enableTwoFactorAuthTotp = ''; totpSecret = '';">
                Cancel
              </button>

              &nbsp;
              &nbsp;

              <button type="button"
                      class="btn btn-success"
                      :disabled="generatingTwoFactorAuthSecret || enableTwoFactorAuthTotp?.length !== 6 || enablingTwoFactorAuth"
                      v-on:click="confirmEnableTwoFactorAuth()">
                Enable
              </button>
            </div>

          </div>
        </div>
      </div>

      <!------------------------- TOASTS ------------------------->

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="copiedTotpSecretToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Success <span>✔️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Your 2FA secret was copied to the clipboard. Go ahead and paste it into your favorite two-factor
            auth app now!
            😃
          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="enableTwoFactorAuthFailedBecauseWrongTotpToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Failure <span>🔴️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Two-factor authentication couldn't be enabled.
            Please make sure to enter a valid 6-digit TOTP code!
          </div>
        </div>
      </div>

    </div>

    <!-- Create new API key modal -->
    <div class="modal fade"
         id="createApiKeyModal"
         tabindex="-1"
         aria-labelledby="createApiKeyModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="createApiKeyModalLabel">
              Create new API Key
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Cancel"/>
          </div>

          <div class="modal-body">

            <fieldset>

              <ExpirationField ref="apiKeyExpirationUTC"
                               @onPressedEnter="confirmApiKeyCreation()"
                               @onChangedExpirationUtcValue="onChangedApiKeyExpirationUtcValue"/>

              <div class="form-group">

                <label class="form-label mt-4">
                  Allowed IP Addresses (comma-separated)
                </label>

                <input type="text"
                       class="form-control"
                       v-on:keyup.enter="confirmApiKeyCreation()"
                       v-model="apiKeyIpWhitelist"
                       @focus="$event.target.select()"
                       placeholder="Comma-separated list of IPv4 or IPv6 addresses">
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  Description text
                </label>

                <textarea type="text"
                          class="form-control"
                          rows="2"
                          maxlength="1024"
                          v-model="apiKeyDescription"
                          @focus="$event.target.select()"
                          placeholder="Add a descriptive text content to your API key"></textarea>
              </div>

            </fieldset>

          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    :disabled="creatingApiKey"
                    v-on:click="confirmApiKeyCreation()">
              Create
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- View or delete existing API key modal -->
    <div class="modal fade"
         id="editApiKeyModal"
         tabindex="-1"
         aria-labelledby="editApiKeyModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="editApiKeyModalLabel">
              API Key
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Cancel"/>
          </div>

          <div class="modal-body">

            <fieldset>

              <div class="form-group">

                <label class="form-label mt-4">
                  Id
                </label>

                <input type="text"
                       class="form-control"
                       readonly
                       v-model="apiKeyId"
                       @focus="$event.target.select()">
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  Key
                </label>

                <input type="password"
                       class="form-control"
                       readonly
                       v-model="apiKeySecret"
                       @focus="$event.target.select()">
              </div>

              <button class="btn btn-primary"
                      v-on:click="copyApiKeyToClipboard()"
                      type="button"
                      :disabled="apiKeyCopied"
                      style="min-width: 81px; margin-top: 8px;">
                {{ apiKeyCopied ? "Copied" : "Copy" }}
              </button>

              <div class="form-group">

                <label class="form-label mt-4">
                  Creation date
                </label>

                <input type="text"
                       class="form-control"
                       readonly
                       :value="!apiKeyTimestampUTC || apiKeyTimestampUTC === 0 ? '' : new Date(apiKeyTimestampUTC * 1000).toUTCString()"
                       @focus="$event.target.select()">
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  Expiration date
                </label>

                <input type="text"
                       class="form-control"
                       readonly
                       :value="!apiKeyExpirationUTC || apiKeyExpirationUTC === 0 ? '(never)' : new Date(apiKeyExpirationUTC * 1000).toUTCString()"
                       @focus="$event.target.select()">
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  Allowed IP Addresses (comma-separated)
                </label>

                <input type="text"
                       class="form-control"
                       readonly
                       v-model="apiKeyIpWhitelist"
                       @focus="$event.target.select()"
                       placeholder="(Access granted from any IP)">
              </div>

              <div class="form-group">

                <label class="form-label mt-4">
                  Description text
                </label>

                <textarea type="text"
                          readonly
                          rows="2"
                          class="form-control"
                          v-model="apiKeyDescription"
                          @focus="$event.target.select()"></textarea>
              </div>

            </fieldset>

          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal">
              Cancel
            </button>

            <button type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                    v-on:click="confirmApiKeyDeletion(this.apiKeyId)">
              Delete
            </button>

          </div>
        </div>
      </div>
    </div>

    <!------------------------- TOASTS ------------------------->

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changePasswordFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Password modification failed. Please ensure all fields are correct and try again!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changePasswordFieldsDoNotMatchToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Careful <span>❗️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          The new password does not match its confirmation field.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changePasswordInsufficientStrengthToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🤕️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Password too weak! Please use at least 7 characters with at least 1 number, 1 lowercase, 1 UPPERCASE and 1
          special character!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changePasswordSuccessToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your password has been modified successfully.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="enabledTwoFactorAuthToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Two-factor authentication has been enabled successfully for your user account. Kudos for improving your opsec!
          😃
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="disabledTwoFactorAuthToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Two-factor authentication has been disabled successfully for your user account. Consider re-enabling it soon!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changeUsernameSuccessToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your username has been changed successfully. Remember to log in with the new one from now on!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="disableTwoFactorAuthFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>😰️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Two-factor authentication couldn't be disabled successfully for your user account.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="enableTwoFactorAuthFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Two-factor authentication couldn't be enabled successfully for your user account.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="changeUsernameFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Username modification failed...
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="createdUserSuccessfullyToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          User has been created successfully.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="savedDefaultLocalesSuccessfullyToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your list of default locales has been saved successfully.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="createApiKeySuccessfullyToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          API Key created successfully and ready for usage!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="deleteApiKeySuccessToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          API Key deleted successfully. It's gone!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="saveDefaultLocalesFailureToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your list of default locales couldn't be saved...
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="createApiKeyFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your API Key couldn't be generated.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="deleteApiKeyFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Your API Key couldn't be deleted.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <small class="version-number"
           v-if="serverVersion && serverVersion.length > 0">
      Server version: {{ serverVersion }}
    </small>

    <br/>

    <small class="version-number">
      Frontend version: {{ frontendVersion }}
    </small>

  </div>
</template>

<script>

import Constants from "@/constants";
import Nav from "@/components/Nav";
import {Modal, Toast, Collapse} from "bootstrap";
import Config from "../../public/js/config";
import QrcodeVue from "qrcode.vue";
import CreateUserAccount from "@/components/CreateUserAccount";
import UsernameField from "@/components/form-fields/UsernameField";
import PasswordField from "@/components/form-fields/PasswordField";
import Users from "@/components/Users";
import {getNicelyFormattedFileSize} from "@/bytes";
import {getUtcNow} from "@/utc";
import {version} from '../../package'
import ExpirationField from "@/components/form-fields/ExpirationField";
import {refreshAuthToken, whoAmI} from "@/auth";

export default {
  name: "Settings",
  components: {
    Users,
    PasswordField,
    UsernameField,
    ExpirationField,
    Nav,
    QrcodeVue,
    CreateUserAccount
  },
  data()
  {
    return {
      totpEnabled: false,
      isRootUser: false,
      expiresSoon: false,
      defaultLocales: [],
      apiKeys: [],
      expirationUTC: 0,
      maxQuotaBytes: 0,
      currentQuotaBytes: 0,
      creatingApiKey: false,
      createApiKeyModal: null,
      editApiKeyModal: null,
      apiKeyId: "",
      apiKeySecret: "",
      apiKeyDescription: "",
      apiKeyTimestampUTC: 0,
      apiKeyExpirationUTC: 0,
      apiKeyIpWhitelist: "",
      apiKeyCopied: false,
      currentPassword: "",
      newPassword: "",
      newPasswordStrength: 0,
      confirmNewPassword: "",
      confirmNewPasswordTotp: "",
      changingPassword: false,
      currentPasswordForUsernameMod: "",
      newUsername: "",
      changingUsername: false,
      usernameAvailable: false,
      confirmNewUsernameTotp: "",
      currentReadAccessPassword: "",
      newReadAccessPassword: "",
      newReadAccessPasswordStrength: 0,
      confirmNewReadAccessPassword: "",
      confirmNewReadAccessPasswordTotp: "",
      changingReadAccessPassword: false,
      confirmUserDeletionTotp: "",
      confirmUserDeletionWord: "",
      totpSecret: "",
      enableTwoFactorAuthTotp: "",
      enableTwoFactorAuthModal: null,
      enableTwoFactorAuthError: null,
      disableTwoFactorAuthTotp: "",
      generatingTwoFactorAuthSecret: true,
      enablingTwoFactorAuth: false,
      disablingTwoFactorAuth: false,
      newDefaultLocale: "",
      savingDefaultLocalesList: false,
      changedDefaultLocalesList: false,
      frontendVersion: version,
      darkTheme: true,
      serverVersion: localStorage.getItem(Constants.localStorageKeyServerVersionNumber)
    }
  },
  mounted()
  {
    document.title = "Glitched Locale Server - Settings";

    this.darkTheme = JSON.parse(localStorage.getItem(Constants.localStorageKeyDarkTheme) ?? JSON.stringify(Constants.settingsDefaultDarkTheme));

    this.emitter.on(Constants.eventNameChangedTheme, darkTheme =>
    {
      this.darkTheme = darkTheme;
    });

    this.refresh();
  },
  methods: {

    handleResponseWhoAmI: async function (response)
    {
      if (!response.ok)
      {
        if (response.status === 401)
        {
          await refreshAuthToken(this.$router);

          response = await whoAmI();

          if (!response.ok)
          {
            localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
            await this.$router.push("/logout");
            return;
          }
        }
        else
        {
          console.error(`Failed to fetch user data from server! Status code ${response.status}`);
          localStorage.setItem(Constants.localStorageKeyLoginReturnUrl, "/settings");
          await this.$router.push("/logout");
          return;
        }
      }

      const responseEnvelope = await response.json();

      if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
      {
        localStorage.setItem(Constants.localStorageKeyLoginReturnUrl, "/settings");
        await this.$router.push("/logout");
        return;
      }

      const userResponseDto = responseEnvelope.items[0];

      this.totpEnabled = userResponseDto.totpEnabled;
      this.maxQuotaBytes = userResponseDto.maxQuotaBytes;
      this.currentQuotaBytes = userResponseDto.currentQuotaBytes;
      this.expirationUTC = userResponseDto.expirationUTC;
      this.apiKeys = userResponseDto.apiKeys;
      this.defaultLocales = userResponseDto.defaultLocales ? userResponseDto.defaultLocales : [];

      if (this.expirationUTC - getUtcNow() < 7776000)
      {
        this.expiresSoon = true;
      }

      if (!this.createApiKeyModal)
      {
        this.createApiKeyModal = new Modal(document.getElementById("createApiKeyModal"));
      }

      if (!this.editApiKeyModal)
      {
        this.editApiKeyModal = new Modal(document.getElementById("editApiKeyModal"));
      }

      this.checkIfAdmin();
    },

    refresh: function ()
    {
      whoAmI().then(async response =>
      {
        await this.handleResponseWhoAmI(response);

      }).catch(async () =>
      {
        localStorage.setItem(Constants.localStorageKeyLoginReturnUrl, "/settings");

        await refreshAuthToken(this.$router);

        // Try again:
        whoAmI().then(response => this.handleResponseWhoAmI(response)).catch(async () => await this.$router.push("/logout"))
      });
    },

    checkIfAdmin: function ()
    {
      fetch(`${Config.webApiBaseUrl}/api/v1/users/admin`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.isRootUser = response.ok;
      }).catch(() =>
      {
        this.isRootUser = false;
      })
    },

    enableTwoFactorAuth: function ()
    {
      this.enableTwoFactorAuthError = null;
      this.generatingTwoFactorAuthSecret = true;

      if (!this.enableTwoFactorAuthModal)
      {
        this.enableTwoFactorAuthModal = new Modal(document.getElementById("enableTwoFactorAuthModal"));
      }

      this.enableTwoFactorAuthModal.show();

      fetch(`${Config.webApiBaseUrl}/api/v1/users/2fa/enable`, {
        method: "PUT",
        body: JSON.stringify({
          userId: localStorage.getItem(Constants.localStorageKeyUserId),
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        setTimeout(() =>
        {
          this.generatingTwoFactorAuthSecret = false;
        }, 420);

        if (!response.ok)
        {
          setTimeout(() =>
          {
            this.enableTwoFactorAuthError = response.status; // TODO: decide if better error msg is needed here
          }, 420);
          return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
          localStorage.setItem(Constants.localStorageKeyLoginReturnUrl, "/settings");
          await this.$router.push("/logout");
          return;
        }

        const responseDto = responseEnvelope.items[0];

        this.totpSecret = responseDto.totpSecret;

      }).catch(error =>
      {
        setTimeout(() =>
        {
          this.enableTwoFactorAuthError = error;
        }, 567);
      });
    },

    confirmEnableTwoFactorAuth: function ()
    {
      if (this.generatingTwoFactorAuthSecret || this.enableTwoFactorAuthTotp?.length !== 6 || this.enablingTwoFactorAuth)
      {
        return;
      }

      try
      {
        const totp = this.enableTwoFactorAuthTotp;
        this.enableTwoFactorAuthTotp = "";
        this.enablingTwoFactorAuth = true;

        fetch(`${Config.webApiBaseUrl}/api/v1/users/2fa/enable/confirm`, {
          method: "PUT",
          body: JSON.stringify({
            totp: totp,
            userId: localStorage.getItem(Constants.localStorageKeyUserId),
          }),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
          }
        }).then(response =>
        {

          this.enablingTwoFactorAuth = false;

          if (response.status === 403)
          {
            new Toast(document.getElementById("enableTwoFactorAuthFailedBecauseWrongTotpToast"), {delay: 6677}).show();
            return;
          }

          this.totpSecret = "";
          this.enableTwoFactorAuthModal.hide();

          if (response.ok)
          {
            this.totpEnabled = true;
            new Collapse(document.getElementById("collapse2FA"));
            new Toast(document.getElementById("enabledTwoFactorAuthToast"), {delay: 6677}).show();
            return;
          }

          new Toast(document.getElementById("enableTwoFactorAuthFailedToast"), {delay: 6677}).show();

        }).catch(() =>
        {
          this.totpSecret = "";
          this.enablingTwoFactorAuth = false;
          this.enableTwoFactorAuthModal.hide();
          new Toast(document.getElementById("enableTwoFactorAuthFailedToast"), {delay: 6677}).show();
        });

      } catch
      {
        this.enablingTwoFactorAuth = false;
      }
    },

    disableTwoFactorAuth: function ()
    {
      if (this.disableTwoFactorAuthTotp?.length !== 6 || this.disablingTwoFactorAuth)
      {
        return;
      }

      this.disablingTwoFactorAuth = true;

      try
      {
        fetch(`${Config.webApiBaseUrl}/api/v1/users/2fa/disable`, {
          method: "PUT",
          body: JSON.stringify({
            totp: this.disableTwoFactorAuthTotp,
            userId: localStorage.getItem(Constants.localStorageKeyUserId),
          }),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
          }
        }).then(response =>
        {
          this.disablingTwoFactorAuth = false;
          this.disableTwoFactorAuthTotp = "";

          if (response.ok)
          {
            this.totpEnabled = false;
            new Toast(document.getElementById("disabledTwoFactorAuthToast"), {delay: 6677}).show();
            return;
          }

          new Toast(document.getElementById("disableTwoFactorAuthFailedToast"), {delay: 6677}).show();

        }).catch(() =>
        {
          this.disableTwoFactorAuthTotp = "";
          this.disablingTwoFactorAuth = false;
          new Toast(document.getElementById("disableTwoFactorAuthFailedToast"), {delay: 6677}).show();
        });

      } catch
      {
        this.disableTwoFactorAuthTotp = "";
        this.disablingTwoFactorAuth = false;
        new Toast(document.getElementById("disableTwoFactorAuthFailedToast"), {delay: 6677}).show();
      }
    },

    copyTotpSecret: function ()
    {
      if (!this.totpSecret)
      {
        return;
      }

      navigator.clipboard.writeText(this.totpSecret);

      new Toast(document.getElementById("copiedTotpSecretToast"), {delay: 6677}).show();
    },

    clearPasswordModificationForm: function (focusCurrentPwField = true)
    {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.confirmNewPasswordTotp = "";

      this.$refs.newPassword.clear();

      if (focusCurrentPwField)
      {
        document.getElementById("currentPassword")?.focus();
      }
    },

    changePassword: function ()
    {
      if (this.changingPassword)
      {
        return;
      }

      if (this.newPassword !== this.confirmNewPassword)
      {
        new Toast(document.getElementById('changePasswordFieldsDoNotMatchToast')).show();
        this.clearPasswordModificationForm();
        return;
      }

      if (this.newPasswordStrength < 2)
      {
        new Toast(document.getElementById('changePasswordInsufficientStrengthToast')).show();
        this.clearPasswordModificationForm();
        return;
      }

      this.changingPassword = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/passwd`, {
        method: "PUT",
        body: JSON.stringify({
          userId: localStorage.getItem(Constants.localStorageKeyUserId),
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
          totp: this.confirmNewPasswordTotp ?? ""
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          setTimeout(() =>
          {
            this.changingPassword = false;
            this.clearPasswordModificationForm();
            new Toast(document.getElementById('changePasswordFailedToast')).show();
          }, 1337);
          return;
        }

        this.changingPassword = false;
        this.clearPasswordModificationForm();
        new Toast(document.getElementById('changePasswordSuccessToast')).show();

      }).catch(() =>
      {
        setTimeout(() =>
        {
          this.changingPassword = false;
          this.clearPasswordModificationForm();
          new Toast(document.getElementById('changePasswordFailedToast')).show();
        }, 1337);
      });
    },

    clearReadAccessPasswordModificationForm: function (focusCurrentPwField = true)
    {
      this.currentReadAccessPassword = "";
      this.newReadAccessPassword = "";
      this.confirmNewReadAccessPassword = "";
      this.confirmNewReadAccessPasswordTotp = "";

      this.$refs.newReadAccessPassword.clear();

      if (focusCurrentPwField)
      {
        document.getElementById("currentReadAccessPassword")?.focus();
      }
    },

    changeReadAccessPassword: function ()
    {
      if (this.changingReadAccessPassword)
      {
        return;
      }

      if (this.newReadAccessPassword !== this.confirmNewReadAccessPassword)
      {
        new Toast(document.getElementById('changePasswordFieldsDoNotMatchToast')).show();
        this.clearReadAccessPasswordModificationForm();
        return;
      }

      if (this.newReadAccessPassword && this.newReadAccessPasswordStrength < 2)
      {
        new Toast(document.getElementById('changePasswordInsufficientStrengthToast')).show();
        this.clearReadAccessPasswordModificationForm();
        return;
      }

      this.changingReadAccessPassword = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/passwd/read-access`, {
        method: "PUT",
        body: JSON.stringify({
          userId: localStorage.getItem(Constants.localStorageKeyUserId),
          oldPassword: this.currentReadAccessPassword,
          newPassword: this.newReadAccessPassword,
          totp: this.confirmNewReadAccessPasswordTotp ?? ""
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {

        if (!response.ok)
        {
          setTimeout(() =>
          {
            this.changingReadAccessPassword = false;
            this.clearReadAccessPasswordModificationForm();
            new Toast(document.getElementById('changePasswordFailedToast')).show();
          }, 1337);
          return;
        }

        this.changingReadAccessPassword = false;
        this.clearReadAccessPasswordModificationForm();
        new Toast(document.getElementById('changePasswordSuccessToast')).show();

      }).catch(() =>
      {
        setTimeout(() =>
        {
          this.changingReadAccessPassword = false;
          this.clearReadAccessPasswordModificationForm();
          new Toast(document.getElementById('changePasswordFailedToast')).show();
        }, 1337);
      });
    },

    clearUsernameModificationForm: function ()
    {
      this.newUsername = "";
      this.usernameAvailable = false;
      this.confirmNewUsernameTotp = "";
      this.currentPasswordForUsernameMod = "";

      this.$refs.newUsername.clear();
    },

    changeUsername: function ()
    {
      if (this.changingUsername || !this.usernameAvailable || !this.newUsername || this.isRootUser)
      {
        return;
      }

      this.changingUsername = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/username`, {
        method: "PUT",
        body: JSON.stringify({
          userId: localStorage.getItem(Constants.localStorageKeyUserId),
          password: this.currentPasswordForUsernameMod,
          newUsername: this.newUsername,
          totp: this.confirmNewUsernameTotp ?? ""
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {

        this.clearUsernameModificationForm();
        this.changingUsername = false;

        if (!response.ok)
        {
          new Toast(document.getElementById("changeUsernameFailedToast"), {delay: 6677}).show();
          return;
        }

        new Toast(document.getElementById("changeUsernameSuccessToast"), {delay: 6677}).show();
      }).catch(() =>
      {
        new Toast(document.getElementById("changeUsernameFailedToast"), {delay: 6677}).show();
        this.clearUsernameModificationForm();
      });
    },

    onChangedUsernameValue: function (username, available)
    {
      this.newUsername = username;
      this.usernameAvailable = available;
    },

    onChangedPasswordValue: function (pw, strength)
    {
      this.newPassword = pw;
      this.newPasswordStrength = strength;
    },

    onChangedReadAccessPasswordValue: function (pw, strength)
    {
      this.newReadAccessPassword = pw;
      this.newReadAccessPasswordStrength = strength;
    },

    onCreatedUserSuccessfully: function ()
    {
      this.$refs.userCreationModal.clear();
      this.$refs.users.refreshUsersList();

      new Toast(document.getElementById('createdUserSuccessfullyToast')).show();
    },

    getUsedQuotaString: function ()
    {
      return `${getNicelyFormattedFileSize(this.currentQuotaBytes)} / ${getNicelyFormattedFileSize(this.maxQuotaBytes)}`;
    },

    addNewDefaultLocaleToList: function ()
    {
      if (!this.newDefaultLocale)
      {
        return;
      }

      if (this.defaultLocales.indexOf(this.newDefaultLocale) === -1)
      {
        this.changedDefaultLocalesList = true;
        this.defaultLocales.push(this.newDefaultLocale);
      }

      this.newDefaultLocale = "";
    },

    removeDefaultLocaleFromList: function (defaultLocaleToRemove)
    {
      const i = this.defaultLocales.indexOf(defaultLocaleToRemove);
      this.defaultLocales.splice(i, 1);
      this.changedDefaultLocalesList = true;
    },

    saveDefaultLocales: function ()
    {
      if (this.savingDefaultLocalesList)
      {
        return;
      }

      this.savingDefaultLocalesList = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/default-locales`, {
        method: "PUT",
        body: JSON.stringify({
          userId: localStorage.getItem(Constants.localStorageKeyUserId),
          defaultLocales: this.defaultLocales ? this.defaultLocales : []
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(response =>
      {
        this.savingDefaultLocalesList = false;

        if (!response.ok)
        {
          new Toast(document.getElementById('saveDefaultLocalesFailureToast')).show();
          return;
        }

        new Toast(document.getElementById('savedDefaultLocalesSuccessfullyToast')).show();
        this.changedDefaultLocalesList = false;
      }).catch(() =>
      {
        this.savingDefaultLocalesList = false;

        new Toast(document.getElementById('saveDefaultLocalesFailureToast')).show();
      });
    },

    deleteUserAccount: function ()
    {
      if (this.confirmUserDeletionWord?.toLowerCase() !== 'delete')
      {
        return;
      }

      if (this.totpEnabled === true && !this.confirmUserDeletionTotp)
      {
        new Modal(document.getElementById('confirmUserDeletionTotpModal')).show();
        return;
      }

      new Modal(document.getElementById('confirmUserDeletionModal')).show();
    },

    confirmUserAccountDeletion: function ()
    {
      this.$router.push(`/delete-user-account?userId=${localStorage.getItem(Constants.localStorageKeyUserId)}&totp=${this.confirmUserDeletionTotp}`);
    },

    createNewApiKey: function ()
    {
      this.apiKeyId = '';
      this.apiKeySecret = '';
      this.apiKeyIpWhitelist = '';
      this.apiKeyDescription = '';
      this.apiKeyTimestampUTC = 0;
      this.apiKeyExpirationUTC = 0;
      this.$refs.apiKeyExpirationUTC.clear();

      this.createApiKeyModal.show();
    },

    editApiKey: function (apiKey)
    {
      this.creatingApiKey = false;

      this.apiKeyId = apiKey.id;
      this.apiKeySecret = apiKey.key;
      this.apiKeyDescription = apiKey.description;
      this.apiKeyTimestampUTC = apiKey.timestampUTC;
      this.apiKeyExpirationUTC = apiKey.expirationUTC;
      this.apiKeyIpWhitelist = apiKey.allowedIpAddresses?.join(", ");

      this.editApiKeyModal.show();
    },

    onChangedApiKeyExpirationUtcValue: function (expUtc)
    {
      this.apiKeyExpirationUTC = expUtc;
    },

    confirmApiKeyCreation: function ()
    {
      if (this.creatingApiKey)
      {
        return;
      }

      this.creatingApiKey = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/users/${localStorage.getItem(Constants.localStorageKeyUserId)}/api-keys`, {
        method: "POST",
        body: JSON.stringify({
          description: this.apiKeyDescription,
          expirationUTC: this.apiKeyExpirationUTC,
          allowedIpAddresses: this.apiKeyIpWhitelist?.replace(' ', '')?.split(',')
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        this.creatingApiKey = false;

        if (!response.ok)
        {
          new Toast(document.getElementById('createApiKeyFailedToast')).show();
          return;
        }

        new Toast(document.getElementById('createApiKeySuccessfullyToast')).show();

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
          new Toast(document.getElementById('createApiKeyFailedToast')).show();
          return;
        }

        this.refresh();

        this.createApiKeyModal.hide();

        this.editApiKey(responseEnvelope.items[0]);

      }).catch(() =>
      {
        this.creatingApiKey = false;
        new Toast(document.getElementById('createApiKeyFailedToast')).show();
      });
    },

    confirmApiKeyDeletion: function (apiKeyId)
    {
      if (confirm("Are you sure?"))
      {
        if (confirm("Are you ABSOLUTELY SURE? WARNING: Deleting an API key while one or more clients are still relying on it will make them immediately lose access to the translations! Only confirm if you're really sure you're not using this API key anywhere anymore!"))
        {
          fetch(`${Config.webApiBaseUrl}/api/v1/users/${localStorage.getItem(Constants.localStorageKeyUserId)}/api-keys/${apiKeyId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
            }
          }).then(response =>
          {
            if (response.ok)
            {
              new Toast(document.getElementById('deleteApiKeySuccessToast')).show();
              this.editApiKeyModal.hide();
              this.refresh();
            }
            else
            {
              new Toast(document.getElementById('deleteApiKeyFailedToast')).show();
            }
          }).catch(() =>
          {
            new Toast(document.getElementById('deleteApiKeyFailedToast')).show();
          });
        }
      }
    },

    copyApiKeyToClipboard: function ()
    {
      navigator.clipboard.writeText(this.apiKeySecret);

      this.apiKeyCopied = true;

      setTimeout(() =>
      {
        this.apiKeyCopied = false;
      }, 2400);
    },

    getTableRowColorByExpirationDate: function (expUtc)
    {
      if (!expUtc || expUtc === 0 || Math.abs((expUtc * 1000) - new Date().getTime()) > 172800000)
      {
        return (this.darkTheme ? 'table-secondary' : '');
      }

      if ((expUtc * 1000) - new Date().getTime() < 0)
      {
        return (this.darkTheme ? 'table-danger' : '');
      }

      return (this.darkTheme ? 'table-warning' : '');
    }
  }
}
</script>

<style scoped>

.enable-2fa-btn {
  width: 100%;
}

#totpActivationQR {
  width: 100%;
  margin-top: -16px;
  margin-bottom: -16px;
}

.progress {
  height: 2px;
  margin-top: 8px;
}

.container {
  padding-bottom: 16px;
}

.remove-locale-from-list-badge-btn {
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.remove-locale-from-list-badge-btn:hover {
  filter: brightness(0.8);
}

.version-number {
  font-size: 0.8rem;
  margin-left: 0.25rem;
  filter: opacity(0.45);
}

.form-check-input,
.table-secondary,
.table-warning,
tr {
  cursor: pointer;
}

</style>
