export default class Config
{
    static get webApiBaseUrl()
    {
        if (process.env.NODE_ENV === 'development')
        {
            return "http://localhost:8827";
        }
        return "https://api.locales.glitchedpolygons.com";
    }
}
