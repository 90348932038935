export function getUtcFromDate(date)
{
    return Math.floor(date.getTime() / 1000);
}

export function getUtcNow()
{
    return getUtcFromDate(new Date());
}

export function getDateFromUtc(unixTimestamp)
{
    return new Date(unixTimestamp * 1000);
}

export function getDateTimeFromDate(date)
{
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const days = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];

    return `${date.getFullYear()}-${months[date.getMonth()]}-${days[date.getDate()]}T${hours[date.getHours()]}:${minutes[date.getMinutes()]}`;
}

export function getDateTimeFromUtc(unixTimestamp)
{
    const dateTime = getDateFromUtc(unixTimestamp);
    return getDateTimeFromDate(dateTime);
}
